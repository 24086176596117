<template>
  <div class="about">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Sobre</li>
      </ol>
    </nav>
    <form class="form-control">
      <div class="mb-3">
        <label class="form-label" for="userId">Usuário ID:</label>
        <input class="form-control" id="userId" type="text" required v-model="userId" readonly />
      </div>
      <div class="mb-3">
        <label class="form-label" for="userDetails">Detalhes:</label>
        <input class="form-control" id="userDetails" type="text" required v-model="userDetails" readonly />
      </div>
      <div class="mb-3">
        <label class="form-label" for="userProvider">Provedor:</label>
        <input class="form-control" id="userProvider" type="text" required v-model="userProvider" readonly />
      </div>      
    </form>
  </div>
</template>

<script>
export default {
  name: "about",
  inject: ['userInfo'],
  data() {
    return {
      userId: "",
      userDetails: "",
      userProvider: ""
    }
  },
  created() {
    this.userId = this.userInfo.userId;
    this.userDetails = this.userInfo.userDetails;
    this.userProvider = this.userInfo.identityProvider;
  }
}
</script>